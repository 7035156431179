$(function () {
  $(".tabs ul li").click(function () {
    var tabId = this.id;
    var contentId = this.id + "_content";
    $(".tabs ul li").removeClass("selected");
    $(".tabs ul li#" + tabId).addClass("selected");

    $(".tabs article").removeClass("selected");
    $(".tabs article#" + contentId).addClass("selected");
  });

});
